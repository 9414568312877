<template>
  <div class="DriverManagement">
    <h2>司机管理</h2>
    <el-button type="primary" @click="handleAdd" :disabled="onCheckBtnAuthority('DriverManagementAdd')">添加</el-button>
    <div class="searchWrap">
      <el-row>
        <el-col :span="5">
          <div class="sb">
            <span>司机姓名</span>
            <el-input
              class="searchInput"
              size="small"
              placeholder="请输入内容"
              v-model="driverName"
              clearable
            >
            </el-input>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="sb">
            <span>身份证号</span>
            <el-input
              class="searchInput"
              size="small"
              placeholder="请输入内容"
              v-model="driverIdCard"
              clearable
            >
            </el-input>
          </div>
        </el-col>
        <el-col :span="14">
          <el-button type="primary" size="small" @click="onGetListData"
            >搜索</el-button
          >
        </el-col>
      </el-row>
    </div>
    <el-table :data="listData" style="margin-top: 20px">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="deviceSn" label="设备SN码"> </el-table-column>
      <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
      <el-table-column prop="manufacturerName" label="设备厂商"> </el-table-column>
      <el-table-column prop="driverName" label="司机姓名"> </el-table-column>
      <el-table-column prop="driverIdCard" label="身份证号"> </el-table-column>
      <el-table-column prop="employmentDate" label="入岗日期"> </el-table-column>
      <el-table-column prop="specialWorkPermit" label="特种作业证"> </el-table-column>
      <el-table-column align="right" width="210">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleDetail(scope.row)" :disabled="onCheckBtnAuthority('DriverManagementDetail')"
            >详情</el-button
          >
          <el-button size="mini" @click="handleEdit(scope.row)" :disabled="onCheckBtnAuthority('DriverManagementEdit')"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.row)" :disabled="onCheckBtnAuthority('DriverManagementDel')"
            >删除</el-button
          >

        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="totals"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onGetListData"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 200, 500]"
    >
    </el-pagination>

    <el-dialog
      title="司机信息"
      :visible.sync="showFormData"
      width="40%"
      :before-close="handleClose"
    >
      <el-form :model="formData" :rules="formRules" ref="ruleForm" label-width="120px">
        <el-form-item label="设备SN码" prop="deviceId">
          <el-select
              v-model="formData.deviceId"
              :disabled="formType == 'detail'"
              clearable
              placeholder="请选择"
              style="width: 100%;"
            >
              <el-option
                v-for="item in deviceList"
                :key="item.id"
                :label="item.deviceName"
                :value="item.id"
              >
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="司机姓名" prop="driverName">
          <el-input clearable :disabled="formType == 'detail'" v-model="formData.driverName"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="driverIdCard">
          <el-input clearable :disabled="formType == 'detail'" v-model="formData.driverIdCard"></el-input>
        </el-form-item>
        <el-form-item label="特种作业证" prop="specialWorkPermit">
          <el-input clearable :disabled="formType == 'detail'" v-model="formData.specialWorkPermit"></el-input>
        </el-form-item>
        <el-form-item label="入岗日期" prop="employmentDate">
          <el-date-picker
              clearable
              :disabled="formType == 'detail'"
              v-model="formData.employmentDate"
              type="date"
              value-format="yyyy-MM-dd"
              style="width: 100%;"
              placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" v-if="formType != 'detail'">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


    
<style lang="less" scoped>
.DriverManagement {
  padding: 20px;
}
</style>
    
<script>
export default {
  name: "DriverManagement",
  components: {},
  data() {
    return {
      pageBtn: [],
      pageSize: 10,
      pageNum: 1,
      totals: 0,
      listData: [],
      driverName: "",
      driverIdCard: "",
      deviceList: [],
      showFormData: false,
      formData: {
        projectId: null,
        deviceId: null,
        driverId: null,
        driverName: null,
        driverIdCard: null,
        specialWorkPermit: null,
        employmentDate: null,
      },
      formRules: {
        deviceId: [{ required: true, message: "必选项", trigger: "blur" }],
        driverName: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      formType: null,
    };
  },
  computed: {},
  mounted() {
    this.onGetPageBtn()
    this.onGetListData();
    this.onGetDeviceList()
  },
  methods: {
    // 获取当前页面按钮权限
    onGetPageBtn: function () {
      var that = this;
      var data = {
        id: "1710927449740181506",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/menu/buttons",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.pageBtn = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 检查按钮权限
    onCheckBtnAuthority: function (code) {
      var disable = true;
      if (this.pageBtn.length) {
        this.pageBtn[0].children.forEach((item) => {
          if (item.code == code) {
            disable = false;
          }
        });
      }
      return disable;
    },
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取主列表数据
    onGetListData: function () {
      var that = this;
      var data = {
        projectId: localStorage.getItem('projectId'),
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        driverName: this.driverName,
        driverIdCard: this.driverIdCard,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/towerDriver/towerDriverSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.listData = res.data.dataList;
            that.totals = res.data.totals;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换分页大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.onGetListData();
    },
    // 获取设备列表
    onGetDeviceList: function () {
      var that = this;
      var data = {
        pageSize: 0,
        pageNum: 1,
        projectId: localStorage.getItem("projectId"),
        manufacturerId: this.manufacturerId,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/towerDevice/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.deviceList = res.data.dataList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 关闭
    handleClose: function () {
      this.formType = ''

      this.formData.projectId = ''
      this.formData.deviceId = ''
      this.formData.driverId = ''
      this.formData.driverName = ''
      this.formData.driverIdCard = ''
      this.formData.specialWorkPermit = ''
      this.formData.employmentDate = ''

      this.showFormData = false
      this.$refs["ruleForm"].clearValidate();
    },
    // 添加
    handleAdd: function () {
      this.formType = 'add'
      this.formData.projectId = localStorage.getItem('projectId')
      this.showFormData = true
    },
    // 编辑
    handleEdit: function (row) {
      this.formType = 'edit'

      this.formData.projectId = localStorage.getItem('projectId')
      this.formData.deviceId = row.id
      this.formData.driverId = row.driverId
      this.formData.driverName = row.driverName
      this.formData.driverIdCard = row.driverIdCard
      this.formData.specialWorkPermit = row.specialWorkPermit
      this.formData.employmentDate = row.employmentDate

      this.showFormData = true
    },
    // 详情
    handleDetail: function (row) {
      this.formType = 'detail'

      this.formData.projectId = localStorage.getItem('projectId')
      this.formData.deviceId = row.id
      this.formData.driverId = row.driverId
      this.formData.driverName = row.driverName
      this.formData.driverIdCard = row.driverIdCard
      this.formData.specialWorkPermit = row.specialWorkPermit
      this.formData.employmentDate = row.employmentDate

      this.showFormData = true
    },
    // 确认提交数据
    handleSubmit: function () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$confirm("此操作将提交该数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.onSubmit();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    // 执行提交
    onSubmit: function () {
      var that = this;
      var data = this.formData;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/towerDriver/towerDriverAdd",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.handleClose();
            that.onGetListData();
            that.$message({
              type: "success",
              message: "操作成功!",
            });
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除
    handleDelete: function (row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onDelete(row.driverId);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 执行删除
    onDelete: function (id) {
      var that = this;
      var data = {
        driverId: id,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/towerDriver/towerDriverDele",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.onGetListData();
            that.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
